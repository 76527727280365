const apiRoutes = {
  AUTH: {
    LOGIN: "user/create/otp",
    SIGNUP:"user/signup",
    VERIFY_OTP: "user/verify-phone",
    REGISTER: "/register",
  },
  USER: {
    DETAILS: "/user-details",
    BYID: (id: string) => `/user/${id}`,
    UPDATE_PROFILE: "user/update-profile",
    WALLET_BALANCE: "user/wallet-balance",
    WALLET_TRANSACTION: "user/wallet-transaction",
    WITHDRAW_AMOUNT: "user/payout-request",
    MY_GAME: "user/my-game",
    ORDER_HISTORY: "user/order-history"
  },
  GAME: {
    CARD_LIST: "user/card/cards",
    GAME_LIST: "user/card/game-list",
    JOIN_GAME: "user/card/join-game",
    PLAY_GAME: "user/card/play-game",
    GAME_UPDATE: "user/card/play-game-update",
    GAME_LEFT: "user/card/card-game-left"
  },
  ENQUIRY: {
    SEND:'user/send-inquire',
    GET:'user/inquire',
  },
  LOCATION: {
    STATE :'state',
    CITY: 'city'
  },
  ORDER: {
    CREATE_ORDER: "user/create-order",
    CREATE_PAYMENT_ORDER: "user/create-payment-order",
    ORDER_INVOICE: (id: number) => `user/invoice/${id}`
  }
};

export default apiRoutes;
