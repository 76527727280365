import { Modal, ModalDialog, Typography } from "@mui/joy";
import React, { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import enquiryIcon from "../assets/images/question.png";
import customerSupportIcon from "../assets/images/customer-support.png";
import referIcon from "../assets/images/refer.png";
import { appPath } from "routes/routes";
import { useNavigate } from "react-router-dom";
import ReferModal from "./ReferModal";

const modalStyles = {
  p: 0,
  background: "none",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const Footer = () => {
  const [activeModal, setActiveModal] = useState<
    | "privacy-policy"
    | "tnc"
    | "refundPolicy"
    | "gameRules"
    | "refer-and-earn"
    | ""
  >("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  function openModal(
    value:
      | "privacy-policy"
      | "tnc"
      | "refundPolicy"
      | "gameRules"
      | "refer-and-earn"
      | ""
  ) {
    setActiveModal(value);
    setIsModalOpen(true);
  }

  function closeModal() {
    setActiveModal("");
    setIsModalOpen(false);
  }

  return (
    <div className="flex flex-col-reverse md:flex-row items-center justify-between w-full px-10">
      <div className="w-[136px] hidden md:block"></div>
      <div
        className="text-[#FFFFFF] w-max p-2 md:p-4 rounded-lg"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, #DA1E1E 0%, #DC2525 54%, #AD1717 100%)",
        }}
      >
        <div className="grid gap-2">
          <div className="flex justify-center items-center text-[10px]">
            <button
              className="border-r-2 pr-1 md:pr-2 border-[#FFFFFF]"
              onClick={() => openModal("privacy-policy")}
            >
              Privacy Policy
            </button>
            <button
              className="border-r-2 px-1 md:px-2 border-[#FFFFFF]"
              onClick={() => {
                openModal("refundPolicy");
              }}
            >
              Refund Policy
            </button>
            <button
              className="border-r-2 px-1 md:px-2 border-[#FFFFFF]"
              onClick={() => {
                openModal("gameRules");
              }}
            >
              Game Rules
            </button>
            <button
              className="pl-1 md:pl-2"
              onClick={() => {
                openModal("tnc");
              }}
            >
              Terms & Conditions
            </button>
          </div>
          <div className="text-[10px] flex items-center">
            <p>Designed & Developed By:&nbsp;</p>
            <p className="font-semibold">GAMATCH ENTER (OPC) PRIVATE LIMITED</p>
          </div>
        </div>

        <Modal open={isModalOpen} onClose={closeModal}>
          <ModalDialog sx={modalStyles}>
            <Typography>
              <div
                className={`bg-white border-2 border-red-600 rounded-2xl overflow-hidden flex flex-col gap-5 p-6 text-black m-auto max-w-[80vw] w-max`}
                style={{
                  borderColor:
                    "linear-gradient(113.57deg, #830B0B 0%, #F90505 56.81%, #6D1212 103.3%)",
                }}
              >
                {activeModal == "privacy-policy" && (
                  <div>
                    <div className="flex justify-end">
                      <RiCloseLine
                        className="text-2xl cursor-pointer"
                        onClick={() => closeModal()}
                      />
                    </div>
                    <div className="h-[80vh] md:h-[500px] overflow-auto text-xs md:text-base">
                      <div id="terms-container">
                        <h1 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          1. Introduction
                        </h1>
                        <p>
                          Welcome to Navi Card Game, GAMATCH ENTERTAINMENT . We
                          value your privacy and are committed to protecting
                          your personal information. This Privacy Policy
                          outlines how we collect, use, store, and share your
                          information when you use our game, website, and
                          related services.
                        </p>
                        <br />

                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          2. In GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED we
                          have the following game:
                        </h2>
                        <ul>
                          <li>Navi Card Game</li>
                        </ul>
                        <br />

                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          3. Information We Collect
                        </h2>
                        <p>
                          We may collect the following types of information:
                        </p>
                        <p>
                          <strong>Personal Information:</strong> This includes
                          your name, email address, and contact details. We are
                          not taking any other information.
                        </p>
                        <p>
                          <strong>Device Information:</strong> We are not taking
                          any device information.
                        </p>
                        <p>
                          <strong>Usage Data:</strong> We are not collecting any
                          data.
                        </p>
                        <p>
                          <strong>Cookies and Tracking Technologies:</strong> We
                          are not saving any cookies.
                        </p>
                        <br />

                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          4. How We Use Your Information
                        </h2>
                        <p>We are not using any information.</p>
                        <br />
                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          5. Sharing Your Information
                        </h2>
                        <p>We are not sharing your information.</p>
                        <br />
                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          6. Data Security
                        </h2>
                        <p>
                          We implement appropriate technical and organizational
                          measures to protect your information from unauthorized
                          access, loss, or misuse. However, no system can be
                          completely secure, and we cannot guarantee the
                          absolute security of your data.
                        </p>
                        <br />

                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          7. Your Rights and Choices
                        </h2>
                        <p>
                          You have the following rights regarding your personal
                          information:
                        </p>
                        <p>
                          <strong>Access and Update:</strong> You can access and
                          update your personal information by logging into your
                          account or contacting us directly.
                        </p>
                        <p>
                          <strong>Delete or Deactivate:</strong> You may request
                          the deletion or deactivation of your account and
                          personal information by contacting us.
                        </p>
                        <br />
                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          8. Children’s Privacy
                        </h2>
                        <p>
                          Our game and website are not intended for children
                          under the age of 18. We do not knowingly collect
                          personal information from children. If we become aware
                          that a child’s information has been collected without
                          parental consent, we will take steps to delete it.
                        </p>
                        <br />
                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          9. International Data Transfers
                        </h2>
                        <p>
                          If you are accessing our game or website from outside
                          of India, please be aware that your information may be
                          transferred to, stored, and processed in a country
                          other than your own. We ensure that any such transfers
                          comply with applicable data protection laws.
                        </p>
                        <br />

                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          10. Changes to This Privacy Policy
                        </h2>
                        <p>
                          We may update this Privacy Policy from time to time to
                          reflect changes in our practices or legal
                          requirements. We will notify you of any significant
                          changes by posting the updated policy on our website
                          or within the game.
                        </p>
                        <br />
                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          11. Developer Details
                        </h2>
                        <p>
                          If you have specific inquiries or require technical
                          assistance related to our services, you can reach out
                          to our developer team directly:
                        </p>
                        <p className="contact">
                          Developer Email: gamatchentertainment@gmail.com
                          <br />
                          Developer Phone: +91-9828522772
                        </p>
                        <br />
                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          12. Contact Us
                        </h2>
                        <p>
                          If you have any questions or concerns about this
                          Privacy Policy or your personal information, please
                          contact us at:
                        </p>
                        <p className="contact">
                          GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED
                          <br />
                          11, ANAND VIHAR COLONY, Shree Sadan, Tekri Madri Link
                          Road
                          <br />
                          Kundan Shoe Maker, Udaipur (313001), Rajasthan.
                          <br />
                          Email: gamatchentertainment@gmail.com
                          <br />
                          Phone: +91-9828522772
                        </p>
                        <br />
                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          Privacy Policy Statement
                        </h2>
                        <p>
                          “By using Navi Card Game, you agree to the collection
                          and use of your information as described in this
                          Privacy Policy. We encourage you to review this policy
                          periodically for any updates.”
                        </p>
                        <br />

                        <h2 className="font-bold md:text-[20px] md:leading-[24px] text-base leading-[18px]">
                          Additional Notes:
                        </h2>
                        <p>
                          Ensure that your privacy policy is easily accessible
                          on your website, such as in the footer or during the
                          account registration process. Tailor the policy to
                          reflect specific data practices and legal requirements
                          in your jurisdiction, particularly regarding GDPR,
                          CCPA, or other data protection regulations. It is
                          advisable to consult with a legal professional to
                          ensure compliance with applicable data protection
                          laws.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {activeModal == "tnc" && (
                  <div>
                    <div className="flex justify-end">
                      <RiCloseLine
                        className="text-2xl cursor-pointer"
                        onClick={() => closeModal()}
                      />
                    </div>
                    <Typography>
                      <div className="h-[80vh] md:h-[500px] overflow-auto">
                        <h1 className="text-base md:text-2xl font-semibold">
                          GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED
                        </h1>
                        <p className="text-xs md:text-base">
                          Welcome to GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED
                          (“GAMATCH ENTERTAINMENT”, “we”, “us”, or “our”). This
                          document outlines the terms and conditions (“Terms”)
                          governing your interaction with our company and its
                          services.
                        </p>

                        <h2 className="text-sm md:text-xl font-semibold">
                          1. Acceptance of Terms
                        </h2>
                        <p className="text-xs md:text-base">
                          By accessing or using GAMATCH ENTERTAINMENT’s
                          services, you agree to be bound by these Terms. If you
                          do not agree to these Terms, you may not access or use
                          our services.
                        </p>

                        <h2 className="text-sm md:text-xl font-semibold">
                          2. Services
                        </h2>
                        <p className="text-xs md:text-base">
                          GAMATCH ENTERTAINMENT provides entertainment services,
                          including but not limited to gaming platforms,
                          software, and related content.
                        </p>

                        <h2 className="text-sm md:text-xl font-semibold">
                          3. Intellectual Property
                        </h2>
                        <p className="text-xs md:text-base">
                          All content, including games, software, text,
                          graphics, logos, and trademarks, provided by GAMATCH
                          ENTERTAINMENT are the intellectual property of GAMATCH
                          ENTERTAINMENT or its licensors. Unauthorized use of
                          this content is prohibited.
                        </p>

                        <h2 className="text-sm md:text-xl font-semibold">
                          4. Account Creation
                        </h2>
                        <p className="text-xs md:text-base">
                          You may be required to create an account to access
                          certain features of GAMATCH ENTERTAINMENT’s services.
                          You are responsible for maintaining the
                          confidentiality of your account information and for
                          all activities that occur under your account.
                        </p>

                        <h2 className="text-sm md:text-xl font-semibold">
                          5. Withdrawal Policy for Card Game
                        </h2>
                        <p className="text-xs md:text-base">
                          <strong>Minimum Withdrawal:</strong> Users must
                          withdraw a minimum of ₹500.
                        </p>
                        <p>
                          <strong>Maximum Withdrawal:</strong> Users can
                          withdraw up to ₹50,000 in a single transaction.
                        </p>
                        <p>
                          <strong>TDS Deductions:</strong>
                        </p>
                        <ul>
                          <li>
                            No TDS will be deducted for withdrawal amounts below
                            ₹10,000.
                          </li>
                          <li>
                            For amounts above ₹10,000, a 30% TDS will be
                            deducted, as per government regulations.
                          </li>
                        </ul>
                        <p>
                          <strong>Processing Time:</strong> Withdrawal requests
                          will be processed within 24-48 hours.
                        </p>
                        <p></p>
                        <h2 className="text-sm md:text-xl font-semibold">
                          6. Privacy
                        </h2>
                        <p className="text-xs md:text-base">
                          GAMATCH ENTERTAINMENT respects your privacy and
                          protects your personal information in accordance with
                          applicable laws and regulations. Please refer to our
                          Privacy Policy for details on how we collect, use, and
                          protect your information.
                        </p>

                        <h2 className="text-sm md:text-xl font-semibold">
                          7. Limitation of Liability
                        </h2>
                        <p className="text-xs md:text-base">
                          GAMATCH ENTERTAINMENT shall not be liable for any
                          direct, indirect, incidental, special, consequential,
                          or punitive damages arising out of or related to your
                          use of our services.
                        </p>

                        <h2 className="text-sm md:text-xl font-semibold">
                          8. Termination
                        </h2>
                        <p className="text-xs md:text-base">
                          GAMATCH ENTERTAINMENT reserves the right to terminate
                          or suspend your access to our services at any time,
                          without prior notice or liability.
                        </p>

                        <h2 className="text-sm md:text-xl font-semibold">
                          9. Governing Law
                        </h2>
                        <p className="text-xs md:text-base">
                          These Terms shall be governed by and construed in
                          accordance with the laws of India. Any disputes
                          arising out of or related to these Terms shall be
                          subject to the exclusive jurisdiction of the courts in
                          India.
                        </p>

                        <h2 className="text-sm md:text-xl font-semibold">
                          10. Changes to Terms
                        </h2>
                        <p className="text-xs md:text-base">
                          GAMATCH ENTERTAINMENT may update these Terms from time
                          to time. We will notify you of any changes by posting
                          the updated Terms on our website.
                        </p>

                        <h2 className="text-sm md:text-xl font-semibold">
                          11. Contact Us
                        </h2>
                        <p className="text-xs md:text-base">
                          If you have any questions or concerns about these
                          Terms, please contact us at the following:
                        </p>
                        <p className="text-xs md:text-base">
                          GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED
                          <br />
                          11, ANAND VIHAR COLONY, Shree Sadan, Tekri Madri Link
                          Road
                          <br />
                          Kundan Shoe Maker, Udaipur (313001), Rajasthan.
                          <br />
                          Email: navismartgame@gmail.com
                        </p>

                        <p className="text-xs md:text-base">
                          By accessing or using GAMATCH ENTERTAINMENT’s
                          services, you acknowledge that you have read,
                          understood, and agree to be bound by these Terms.
                        </p>
                      </div>
                    </Typography>
                  </div>
                )}
                {activeModal == "refundPolicy" && (
                  <>
                    <div className="flex justify-end">
                      <RiCloseLine
                        className="text-2xl cursor-pointer"
                        onClick={() => closeModal()}
                      />
                    </div>
                    <div className="h-[80vh] md:h-[500px] overflow-auto">
                      <h1 className="text-lg md:text-2xl font-semibold">
                        Refund and Cancellation Policy
                      </h1>
                      <p className="text-xs md:text-base">
                        At GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED (“GAMATCH
                        ENTERTAINMENT”, “we”, “us”, or “our”), we prioritize
                        your satisfaction with our services. While we strive to
                        offer seamless experiences, we understand that
                        situations may arise where you may need to cancel a
                        purchase or seek a refund. To ensure transparency and
                        fairness, please acquaint yourself with our refund and
                        cancellation policy outlined below:
                      </p>

                      <h2 className="text-base font-semibold">
                        1. Refund Policy
                      </h2>
                      <p className="text-xs md:text-base">
                        <strong>Digital Purchases:</strong> Once a purchase is
                        made through our platform, including virtual items,
                        currency, or in-game content, it is considered final and
                        non-refundable. This policy aligns with the nature of
                        digital content, where purchases cannot be reversed.
                      </p>
                      <p className="text-xs md:text-base">
                        <strong>Subscription Services:</strong> We don’t have
                        subscription services.
                      </p>

                      <h2 className="text-base font-semibold">
                        2. Cancellation Policy
                      </h2>
                      <p className="text-xs md:text-base">
                        <strong>Subscription Services:</strong> We don’t have
                        subscription services.
                      </p>
                      <p className="text-xs md:text-base">
                        <strong>Prepaid Services:</strong> For prepaid services
                        or products, such as game credits or membership
                        packages, cancellation may not be feasible once the
                        service has been activated or the product has been
                        utilized. Please don’t hesitate to contact us should you
                        require assistance with cancellation requests.
                      </p>

                      <h2 className="text-base font-semibold">3. Contact Us</h2>
                      <p className="text-xs md:text-base">
                        For any queries or concerns regarding our refund and
                        cancellation policy, please don’t hesitate to reach out
                        to us at:
                      </p>
                      <p className="text-xs md:text-base">
                        GAMATCH ENTERTAINMENT (OPC) PRIVATE LIMITED
                        <br />
                        11, ANAND VIHAR COLONY, Shree Sadan, Tekri Madri Link
                        Road
                        <br />
                        Kundan Shoe Maker, Udaipur (313001), Rajasthan.
                        <br />
                        Email: navismartgame@gmail.com
                      </p>

                      <p className="text-xs md:text-base">
                        By utilizing the services provided by GAMATCH
                        ENTERTAINMENT, you acknowledge that you have read,
                        understood, and agreed to adhere to our refund and
                        cancellation policy. We reserve the right to modify or
                        update this policy at any time without prior notice,
                        ensuring continued alignment with our commitment to
                        customer satisfaction.
                      </p>
                    </div>
                  </>
                )}
                {activeModal == "gameRules" && (
                  <>
                    <div className="flex justify-end">
                      <RiCloseLine
                        className="text-2xl cursor-pointer"
                        onClick={() => closeModal()}
                      />
                    </div>
                    <div className="h-[80vh] md:h-[500px] overflow-auto">
                      <h1 className="text-lg md:text-2xl font-semibold">
                        Game Rules
                      </h1>
                      <ul className="text-xs md:text-base list-disc ml-4 mt-2">
                        <li>
                          This game is for entertainment, but you can win
                          rewards by playing the Navi Card game.
                        </li>
                        <li>
                          There is no need for any payment transaction to play a
                          single game.
                        </li>
                        <li>
                          The minimum gameplay amount is 10 INR, and the maximum
                          is 5000 INR.
                        </li>
                        <li>
                          The minimum deposit amount is 100 INR, and the maximum
                          deposit amount is 10000 INR.
                        </li>
                        <li>
                          Once the play amount is deducted from your side, it
                          cannot be canceled. You must play the selected game.
                          If you leave the game in between, the amount will not
                          be refunded to you.
                        </li>
                        <li>
                          You cannot claim from the company for any win or loss.
                          The company has full rights to declare the winner.
                        </li>
                        <li>
                          If you log out from the game due to an internet issue
                          or any other technical issue, the company is not
                          liable to pay you any amount.
                        </li>
                        <li>
                          For further information, call the customer care number
                          or use the WhatsApp chat option.
                        </li>
                      </ul>

                      <br />

                      <h2 className="text-lg md:text-2xl font-semibold">
                        हिंदी में नियम
                      </h2>
                      <ul className="text-xs md:text-base list-disc ml-4 mt-2">
                        <li>
                          यह गेम मनोरंजन के लिए है, लेकिन आप नवी कार्ड गेम खेलकर
                          पुरस्कार जीत सकते हैं।
                        </li>
                        <li>
                          एक गेम खेलने के लिए किसी भी प्रकार के भुगतान की
                          आवश्यकता नहीं है।
                        </li>
                        <li>न्यूनतम गेम प्ले राशि 10 है और अधिकतम 5000 है।</li>
                        <li>
                          न्यूनतम जमा राशि 100 है और अधिकतम जमा राशि 10000 है।
                        </li>
                        <li>अधिकतम दांव राशि 5000 है।</li>
                        <li>
                          एक बार जब आपके पक्ष से राशि कट जाती है, तो इसे रद्द
                          नहीं किया जा सकता। आपको चुना हुआ गेम खेलना ही होगा।
                          यदि आप बीच में गेम छोड़ते हैं, तो आपकी राशि वापस नहीं
                          की जाएगी।
                        </li>
                        <li>
                          आप किसी भी जीत या हार के लिए कंपनी से दावा नहीं कर
                          सकते। विजेता घोषित करने के सभी अधिकार कंपनी के पास
                          सुरक्षित हैं।
                        </li>
                        <li>
                          यदि आप इंटरनेट या किसी अन्य तकनीकी समस्या के कारण गेम
                          से लॉगआउट हो जाते हैं, तो कंपनी आपको किसी भी राशि का
                          भुगतान करने के लिए उत्तरदायी नहीं होगी।
                        </li>
                        <li>
                          अधिक जानकारी के लिए, ग्राहक सेवा नंबर पर कॉल करें या
                          व्हाट्सएप चैट विकल्प का उपयोग करें।
                        </li>
                      </ul>
                    </div>
                  </>
                )}
                {activeModal === "refer-and-earn" && (
                  <ReferModal handleCloseModal={closeModal} />
                )}
              </div>
            </Typography>
          </ModalDialog>
        </Modal>
      </div>

      {!location.pathname.includes("/game/play") && (
        <div className="flex gap-2 ml-auto mb-2 md:m-0">
          <button
            className="bg-red-bg rounded-full p-2"
            title="Refer&Earn"
            onClick={() => {
              setIsModalOpen(true);
              setActiveModal("refer-and-earn");
            }}
          >
            <img
              src={referIcon}
              alt="refer and earn"
              className="h-6 w-6 object-contain"
            />
          </button>
          <button
            className="bg-red-bg rounded-full p-2"
            title="Enquiry"
            onClick={() => {
              navigate(appPath.enquiry);
            }}
          >
            <img
              src={enquiryIcon}
              alt="enquiry"
              className="h-6 w-6 object-contain"
            />
          </button>
          <button
            className="bg-red-bg rounded-full p-2"
            title="Customer Support"
            onClick={() => {
              window.open("https://api.whatsapp.com/send?phone=919828522772");
            }}
          >
            <img
              src={customerSupportIcon}
              alt="customer support"
              className="h-6 w-6 object-contain"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default Footer;
