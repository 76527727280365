import Routing from "routes/Routing";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "components/Navbar";
import appConfig from "config/appConfig";
import Footer from "components/Footer";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { PusherClient } from "utils/pusher/PusherClient";
import clsx from "clsx";
import userLocalStorage from "hooks/userLocalStorage";
import { appPath } from "routes/routes";

function App() {
  const { pathname } = useLocation();
  const showNav = !appConfig.nonHeaderComps.includes(pathname);
  const navigate = useNavigate();
  const {getLocalStorage} = userLocalStorage();
  const token = getLocalStorage("token");
  const showFooter = !appConfig.nonFooterComps.includes(
    pathname.replace(/\/[0-9]+/g, "")
  );

  useEffect(() => {
    if(!token){
      navigate(appPath.signup);
    }
    PusherClient.connection.bind("connected", () => {
      console.log("Pusher is connected");
    });
  }, []);

  return (
    <div className={clsx("bg-splash app flex flex-col overflow-auto", showNav && showFooter && "gap-4")}>
      {showNav && <Navbar />}
      <Routing />
      {showFooter && <Footer />}
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default App;
