import React, { FC } from "react";

interface Props {
  text: string;
  onClick?: () => void;
  type?: "submit" | "button"
}

const SubmitButton:FC<Props> = ({ text, onClick, type = "button" }) => {

  return (
    <div>
      <button
        type={type}
        onClick={onClick}
        className="border-[1px] border-white rounded-full text-white bg-[#079A04] py-3 px-5 min-w-[250px] w-full"
      >
        {text}
      </button>
    </div>
  );
};

export default SubmitButton;
