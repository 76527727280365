import React from "react";
import CommonModal from "./CommonModal";
import apiService from "api/apiService";
import apiRoutes from "api/apiRoutes";
import { appPath } from "routes/routes";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const LeaveWarnModal = ({ openWarnModal, setOpenWarnModal, gameData }) => {
  const navigate = useNavigate();
  const { remainingTimeValue } = useSelector((state: any) => state?.game);

  const handleLeaveGame = () => {
    const _form = new FormData();
    _form.append("card_game_id", gameData?.gameSession?.card_game_id);
    _form.append("card_game_session_id", gameData?.gameSession?.id);
    _form.append("left_time", `${60000 - remainingTimeValue * 1000}`);
    _form.append("player_id", gameData?.cardGamePlayer?.id);

    apiService
      .post(apiRoutes.GAME.GAME_LEFT, _form)
      .then((response) => {
        setOpenWarnModal(false)
        if (response?.data?.status == "Left") {
          navigate(appPath.home);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <CommonModal
      isModalOpen={openWarnModal}
      setIsModalOpen={setOpenWarnModal}
      content={
        <div className="p-4 grid gap-4">
          <div className="font-semibold">If you leave you lost the game.</div>
          <div className="flex justify-center gap-4 items-center">
            <button
              onClick={() => setOpenWarnModal(false)}
              className="border-2 border-[#FFC9C8] rounded-full py-2 px-4 hover:scale-105 ease-in-out"
            >
              Cancel
            </button>
            <button
              onClick={() => handleLeaveGame()}
              className="bg-[#FFC9C8] border-2 border-[#FFC9C8] rounded-full py-2 px-4 hover:scale-105 ease-in-out"
            >
              Leave
            </button>
          </div>
        </div>
      }
    />
  );
};

export default LeaveWarnModal;
