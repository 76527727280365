import apiRoutes from "api/apiRoutes";
import apiService from "api/apiService";
import CommonModal from "components/CommonModal";
import React, { useEffect, useState } from "react";
import EnquiryModal from "./EnquiryModal";
import { BsBack } from "react-icons/bs";
import { FaLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { appPath } from "routes/routes";

const Enquiry = () => {
  const [enquiryData, setEnquiryData] = useState([]);
  const [openEnquiryModal, setOpenEnquiryModal] = useState(false);
  const navigate = useNavigate();

  const handleGetEnquiries = () => {
    apiService
      .get(apiRoutes.ENQUIRY.GET)
      .then((response) => {
        setEnquiryData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleGetEnquiries();
  }, []);

  return (
    <>
      <CommonModal
        isModalOpen={openEnquiryModal}
        setIsModalOpen={setOpenEnquiryModal}
        content={<EnquiryModal setOpenEnquiryModal={setOpenEnquiryModal} fetchInquiry={handleGetEnquiries} />}
      />
      <div className="flex flex-col h-[calc(100vh-100px)] mx-4 pt-20">
        <div className="flex justify-between">
          <div>
            <p
              className="cursor-pointer flex gap-2 items-center text-white"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaLeftLong />
              Back
            </p>
          </div>
          <button
            className="bg-red-bg py-2 px-4 rounded-md text-white"
            onClick={() => {
              setOpenEnquiryModal(true);
            }}
          >
            + Create New
          </button>
        </div>
        <div className="h-[calc(100vh-136px)] overflow-auto mt-2">
          <table className="w-full">
            <thead>
              <tr className="bg-red-bg text-white italic">
                <th className="p-2">Name</th>
                <th className="p-2">email</th>
                <th className="p-2">Phone Number</th>
                <th className="p-2">Message</th>
              </tr>
            </thead>
            <tbody>
              {enquiryData?.map((enquiry, index) => {
                return (
                  <tr key={index} className="bg-white text-center text-black">
                    <td className="p-2">{enquiry?.name}</td>
                    <td className="p-2">{enquiry?.email}</td>
                    <td className="p-2">{enquiry?.phone}</td>
                    <td className="p-2">{enquiry?.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Enquiry;
