import { createSlice } from "@reduxjs/toolkit";
import { UserInterface } from "./userReducer";

interface InitialStateTypes {
  gamePlayers: "single" | "multi" | "";
  prize: number;
  winner: UserInterface | null;
  walletBalance: number;
  remainingTime: number;
  gameDataObj: any;
  gameStartStatus: boolean;
  playersCount: number;
  musicData: boolean;
}

const initialState = {
  gamePlayers: "",
  prize: 0,
  winner: null,
  walletBalance: 0,
  remainingTimeValue: 60,
  gameDataObj: null,
  gameStartStatus: false,
  playersCount: 1,
  musicData: false
};

const gameSlice = createSlice({
  name: "game",
  initialState: initialState,
  reducers: {
    setGamePlayers: (state, action) => {
      state.gamePlayers = action.payload;
    },
    setPrize: (state, action) => {
      state.prize = action.payload;
    },
    setWinner: (state, action) => {
      state.winner = action.payload;
    },
    setWalletBalance: (state, action) => {
      state.walletBalance = action.payload;
    },
    setRemainingTimeValue: (state, action) => {
      state.remainingTimeValue = action.payload;
    },
    setGameDataObj: (state, action) => {
      state.gameDataObj = action.payload;
    },
    setGameStartStatus: (state, action) => {
      state.gameStartStatus = action.payload;
    },
    setPlayers: (state, action) => {
      state.playersCount = action.payload;
    },
    setBackgroundMusic: (state, action) => {
      state.musicData = action.payload;
    },
  },
});

export const {
  setGamePlayers,
  setPrize,
  setWalletBalance,
  setRemainingTimeValue,
  setGameDataObj,
  setGameStartStatus,
  setPlayers,
  setBackgroundMusic
} = gameSlice.actions;
export default gameSlice.reducer;
