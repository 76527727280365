import logo from "assets/images/Logo.png";
import SubmitButton from "components/buttons/SubmitButton";
import TextInput from "components/inputs/TextInput";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { appPath } from "routes/routes";
import apiService from "api/apiService";
import apiRoutes from "api/apiRoutes";
import userLocalStorage from "hooks/userLocalStorage";
import { useEffect, useState } from "react";
import CommonModal from "components/CommonModal";
import { RiCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email().required("Email is required."),
  phone: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone must be 10 digits.")
    .max(10, "Phone must be 10 digits."),
  countryCode: yup.number().required("Country Code is required."),
  state: yup
    .object({
      id: yup.string().required("State is required"),
      label: yup.string().required("State name is required"),
    })
    .required("State is required"),
  city: yup
    .object({
      id: yup.string().required("City is  required"),
      label: yup.string().required("State name is required"),
    })
    .required("City is  required"),
  referral_code: yup.string().nullable(),
});

const LoginSchema = yup.object().shape({
  name: yup.string().nullable(),
  email: yup.string().email().nullable(),
  phone: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone must be 10 digits.")
    .max(10, "Phone must be 10 digits."),
  countryCode: yup.number().required("Country Code is required."),
  state: yup
    .object({
      id: yup.string().nullable(),
      label: yup.string().nullable(),
    })
    .nullable(),
  city: yup
    .object({
      id: yup.string().nullable(),
      label: yup.string().nullable(),
    })
    .nullable(),
  referral_code: yup.string().nullable(),
});

const Signup = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [showScreen, setShowScreen] = useState<"Signup" | "Login" | "">("Login");
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      countryCode: 0,
      state: { id: "", label: "" },
      city: { id: "", label: "" },
      referral_code: "",
    },
    resolver: yupResolver(showScreen == "Signup" ? schema : LoginSchema),
  });
  const { setLocalStorage, getLocalStorage } = userLocalStorage();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [states, setStates] = useState([
    { id: "", name: "Please select State" },
  ]);

  const [cities, setCities] = useState([
    { id: "", name: "Please select State" },
  ]);

  const getState = () => {
    apiService
      .get(apiRoutes.LOCATION.STATE)
      .then((response) => {
        setStates(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getState();
  }, []);

  const handleGetCities = (id) => {
    apiService
      .get(apiRoutes.LOCATION.CITY + `/${id}`)
      .then((response) => {
        setCities(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const token = getLocalStorage("token");
    if(params?.get("referral_code")){
      setShowScreen("Signup")
      setValue("referral_code", params?.get("referral_code"));
      return;
      // do nothing
    } else if (token) {
      navigate("/");
      return;
    }
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const formData: any = new FormData();
    formData.append("dialing_code", "+91");
    formData.append("phone", data?.phone);
    formData.append("game", "Card");
    if (showScreen == "Signup") {
      formData.append("name", data?.name);
      formData.append("email", data?.email);
      formData.append("state", data?.state?.label);
      formData.append("city", data?.city?.label);
      formData.append("referral_code", data?.referral_code);
    }
    const apiRoute =
      showScreen === "Signup" ? apiRoutes.AUTH.SIGNUP : apiRoutes.AUTH.LOGIN;

    await apiService
      .post(apiRoute, formData)
      .then((response) => {
        if (response?.code == 200) {
          setLocalStorage("user_data", JSON.stringify(response?.data));
          navigate(appPath?.otp, {
            state: {
              data: data,
              from: showScreen == "Signup" ? "Signup" : "Login",
            },
          });
        } else {
          throw response;
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CommonModal
        isModalOpen={openModal}
        setIsModalOpen={setOpenModal}
        content={
          <div>
            <div className="flex justify-end">
              <RiCloseLine
                className="text-2xl cursor-pointer"
                onClick={() => setOpenModal(false)}
              />
            </div>
            <div className="text-black">
              <h2>
                <strong className="ql-font-monospace">
                  Term and Conditions
                </strong>
              </h2>
              <h2>
                <br />
              </h2>
              <h2>
                <br />
              </h2>
              <ol>
                <li>The game is just for fun</li>
                <li>The game is for 18+ only.</li>
                <li>The game involves financial risk </li>
                <li>Play the game with your intelligence and wisdom</li>
                <li>
                  The company will not be responsible for any financial risk
                </li>
              </ol>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <ol>
                <li>गेम सिर्फ मनोरंजन के लिए है&nbsp;</li>
                <li>गेम सिर्फ १८+ ही खेले&nbsp;</li>
                <li>गेम मे वित्तीय झोकीम शामिल है&nbsp;</li>
                <li>गेम अपनी सूझ बुझ और समझदारी के साथ खेले&nbsp;</li>
                <li>
                  वित्तीय झोकीम होने पर कंपनी की कोई जिम्मेदारी नहीं होगी&nbsp;
                </li>
              </ol>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
            </div>
          </div>
        }
      />
      <div className="flex w-full h-screen">
        <div className="w-[50%] hidden md:block"></div>
        <div className="md:w-[50%] bg-white">
          <div className="flex flex-col justify-around items-center h-[80%] md:h-full w-full pb-8">
            <div className="h-[15%]">
              <img
                src={logo}
                alt="logo"
                className="h-[100px] w-full object-contain"
              />
            </div>
            {showScreen == "Signup" ? (
              <div className="w-full mx-auto">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col gap-5 justify-center w-[80%] flex-grow mx-auto"
                >
                  {/* <span className="text-white text-2xl">Sign up</span> */}

                  {/* <Controller name="countryCode" control={control} defaultValue={0} render={({field}) => (
          <TextInput {...field} placeholder="Enter Country Code" error={errors?.countryCode?.message} />
        )} /> */}
                  <div className="grid md:grid-cols-2 gap-4">
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <TextInput
                            {...field}
                            type="text"
                            placeholder="Enter Name"
                            error={errors?.name?.message}
                          />
                        </>
                      )}
                    />

                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <TextInput
                            {...field}
                            type="email"
                            placeholder="Enter Email"
                            error={errors?.email?.message}
                          />
                        </>
                      )}
                    />

                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <TextInput
                            {...field}
                            type="number"
                            placeholder="Enter Mobile Number"
                            error={errors?.phone?.message}
                          />
                        </>
                      )}
                    />

                    <Controller
                      name="state"
                      control={control}
                      defaultValue={{ id: "", label: "" }}
                      render={({ field }) => (
                        <div className="text-left w-full">
                          <select
                            {...field}
                            className="text-[#E8EDF2] focus:outline-none text-[11px] text-sm border-transparent border-[1px] border-white rounded-full block w-full px-4 py-3.5 bg-[#3E3E3E]"
                            onChange={(e) => {
                              const stateName = states.find(
                                (el) => el?.id == e.target.value
                              );
                              const val = {
                                id: stateName?.id,
                                label: stateName?.name,
                              };
                              field.onChange(val);
                              handleGetCities(e.target.value);
                            }}
                            value={field.value?.id}
                          >
                            <option selected hidden>
                              Select State
                            </option>
                            {states?.map((item, index) => (
                              <option key={index} value={item?.id}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                          {errors.state && (
                            <div className="mt-1 pl-2 text-xs text-red-600 font-semibold">
                              {errors.state?.id?.message}
                            </div>
                          )}
                        </div>
                      )}
                    />

                    <Controller
                      name="city"
                      control={control}
                      defaultValue={{ id: "", label: "" }}
                      render={({ field }) => (
                        <div className="pt-2 lg:pt-0">
                          <select
                            {...field}
                            className="text-[#E8EDF2] focus:outline-none text-[11px] text-sm border-transparent border-[1px] border-white rounded-full block w-full px-4 py-3.5 bg-[#3E3E3E]"
                            onChange={(e) => {
                              const cityName = cities.find(
                                (el) => el?.id == e.target.value
                              );
                              const val = {
                                id: cityName?.id,
                                label: cityName?.name,
                              };
                              field.onChange(val);
                            }}
                            value={field.value?.id}
                          >
                            <option selected hidden>
                              Select City
                            </option>
                            {cities?.map((item, index) => (
                              <option key={index} value={item?.id}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                          {errors.city && (
                            <div className="mt-1 pl-2 text-xs text-red-600 font-semibold">
                              {errors.city?.id?.message}
                            </div>
                          )}
                        </div>
                      )}
                    />

                    <Controller
                      name="referral_code"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <TextInput
                            {...field}
                            type="text"
                            placeholder="Enter Referral Code (Optional)"
                          />
                        </>
                      )}
                    />
                  </div>
                  <SubmitButton
                    type="submit"
                    text={loading ? "processing..." : "Signup"}
                  />
                </form>
                <div className="mt-4">
                  <p className="text-center">
                    Already have an account?{" "}
                    <span
                      className="text-blue-500 underline cursor-pointer"
                      onClick={() => {
                        setShowScreen("Login");
                      }}
                    >
                      Login
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <div className="w-full mx-auto">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col gap-5 justify-center w-[80%] flex-grow mx-auto"
                >
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <TextInput
                          {...field}
                          type="number"
                          placeholder="Enter Mobile Number"
                          error={errors?.phone?.message}
                        />
                      </>
                    )}
                  />

                  <SubmitButton
                    type="submit"
                    text={loading ? "processing..." : "Login"}
                  />
                </form>
                <div className="mt-4">
                  <p className="text-center">
                    Don't have an account?{" "}
                    <span
                      className="text-blue-500 underline cursor-pointer"
                      onClick={() => {
                        setShowScreen("Signup");
                      }}
                    >
                      Sign up
                    </span>
                  </p>
                </div>
              </div>
            )}
            <div className="cursor-pointer" onClick={() => setOpenModal(true)}>
              <p className="text-2xl font-bold text-center">Disclaimer</p>
              <p className="text-sm text-center">T&C Apply</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
