import http from "./http";

const apiService = {
  /**
   * @method GET
   * @param {string} url
   * @param {object} params
   * @return {Promise<any>}
   */

  get: async (url: string, params?: any) => {
    try {
      const response = await http.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * @method POST
   * @param {string} url
   * @param {object} data
   * @return {Promise<any>}
   */

  post: async (url: string, data: any) => {
    try {
      const response = await http.post(url, data);
      return response.data ? response.data : response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * @method PUT
   * @param {string} url
   * @param {object} data
   * @return {Promise<any>}
   */

  put: async (url: string, data: any) => {
    try {
      const response = await http.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * @method DELETE
   * @param {string} url
   * @return {Promise<any>}
   */

  delete: async (url: string) => {
    try {
      const response = await http.delete(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default apiService;
