import { yupResolver } from "@hookform/resolvers/yup";
import apiRoutes from "api/apiRoutes";
import apiService from "api/apiService";
import userLocalStorage from "hooks/userLocalStorage";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FaArrowLeft,
  FaLink,
  FaRegThumbsDown,
  FaRegThumbsUp,
  FaRupeeSign,
  FaThumbsUp,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { appPath } from "routes/routes";
import * as yup from "yup";
import TextInput from "./inputs/TextInput";
import { Avatar, TextField } from "@mui/material";
import { MdEdit } from "react-icons/md";
import Rupee from "../assets/images/rupee.png";

const userData = {
  name: "Maun Snow",
  email: "maunsnow@gmail.com",
  countryCode: 91,
  contact: 9999990000,
  walletAmount: 1000,
};

const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email().required("Email is required."),
  profile_photo: yup.mixed().nullable(),
  phone: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone must be 10 digits.")
    .max(10, "Phone must be 10 digits."),
});

interface Props {
  handleCloseModal: () => void;
}

const ProfileBox = ({ handleCloseModal }: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { clearLocalStorage, getLocalStorage, setLocalStorage } =
    userLocalStorage();
  const user = JSON.parse(getLocalStorage("user_data"));
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: user?.name,
      email: user?.email,
      profile_photo: user?.profile_photo ? user?.profile_photo_url : "",
      phone: user?.phone,
      // aadhar_number: "",
    },
    resolver: yupResolver(schema),
  });
  const profileInputRef = useRef<any>(null);
  const [gameData, setGameData] = useState(null);

  const handleSelectProfile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files[0]) {
      setValue("profile_photo", event.target.files[0]);
    }
  };

  function handleLogout() {
    //logout
    clearLocalStorage();
    handleCloseModal();
    navigate(appPath.signup);
  }

  const onSubmit = async (data: any) => {
    setLoading(true);
    const formData: any = new FormData();
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("phone", data?.phone);
    formData.append("profile_photo", watch("profile_photo"));
    await apiService
      .post(apiRoutes.USER.UPDATE_PROFILE, formData)
      .then((response) => {
        setLocalStorage("user_data", JSON.stringify(response?.data));
        setIsEdit(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetMyGameData = () => {
    const _form = new FormData();
    _form.append("game", "Card");
    apiService
      .post(apiRoutes.USER.MY_GAME, _form)
      .then((response) => {
        setGameData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleGetMyGameData();
  }, []);

  return (
    <>
      <div className="flex justify-between items-center w-[90vw] max-w-full text-sm font-semibold">
        <div className="flex items-center gap-2">
          <FaArrowLeft onClick={handleCloseModal} className="cursor-pointer" />
          <span className="text-lg text-black border-2 border-red-500 bg-white rounded-md py-2 px-4 absolute -top-5 left-[50%] -translate-x-[50%]">
            Profile Details
          </span>
        </div>
      </div>

      {isEdit ? (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
          <div className="flex justify-center">
            <div className="relative">
              {user?.profile_photo ? (
                <Avatar
                  sx={{ width: 56, height: 56 }}
                  src={
                    user?.profile_photo_url ||
                    (watch("profile_photo") &&
                      URL.createObjectURL(
                        watch("profile_photo") as File | undefined
                      ))
                  }
                  alt="profile"
                />
              ) : (
                <Avatar sx={{ width: 56, height: 56 }}>
                  {user?.name?.slice(0, 1)}
                </Avatar>
              )}

              {isEdit && (
                <div
                  className="absolute -right-2 bottom-0 bg-black text-white p-1 rounded-full cursor-pointer"
                  onClick={() => profileInputRef.current.click()}
                >
                  <MdEdit />
                  <input
                    ref={profileInputRef}
                    type="file"
                    onChange={handleSelectProfile}
                    name="profile_photo"
                    hidden
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    type="text"
                    placeholder="Enter Name"
                    className="w-full"
                    size="small"
                    error={Boolean(errors?.name?.message)}
                    helperText={errors?.name?.message}
                  />
                </>
              )}
            />
          </div>
          <div>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    type="email"
                    placeholder="Enter Email"
                    className="w-full"
                    size="small"
                    error={Boolean(errors?.email?.message)}
                    helperText={errors?.email?.message}
                  />
                </>
              )}
            />
          </div>
          <div>
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    type="number"
                    placeholder="Enter Phone"
                    className="w-full"
                    size="small"
                    error={Boolean(errors?.phone?.message)}
                    helperText={errors?.phone?.message}
                  />
                </>
              )}
            />
          </div>
          <div className="flex items-center justify-center gap-2">
            <div className="flex justify-center items-center">
              <button
                type="button"
                className="bg-[#FFC9C8] rounded-full py-2 px-4"
                onClick={() => setIsEdit(!isEdit)}
              >
                Cancel
              </button>
            </div>
            <div className="flex justify-center items-center">
              <button
                className="bg-[#FFC9C8] rounded-full py-2 px-4"
                type="submit"
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="rounded-xl bg-[#F9F9F9] w-full flex flex-col px-4 gap-6 relative">
            <div className="flex flex-col items-center justify-center">
              <span className="text-lg font-semibold">{user?.name}</span>
              <span className="text-sm">{user?.id}</span>
            </div>
            <div className="flex items-center gap-4">
              <div className="relative">
                {user?.profile_photo ? (
                  <Avatar
                    sx={{ width: 56, height: 56 }}
                    src={
                      user?.profile_photo_url ||
                      (watch("profile_photo") &&
                        URL.createObjectURL(
                          watch("profile_photo") as File | undefined
                        ))
                    }
                    alt="profile"
                  />
                ) : (
                  <Avatar sx={{ width: 56, height: 56 }}>
                    {user?.name?.slice(0, 1)}
                  </Avatar>
                )}

                {isEdit && (
                  <div
                    className="absolute -right-2 bottom-0 bg-black text-white p-1 rounded-full cursor-pointer"
                    onClick={() => profileInputRef.current.click()}
                  >
                    <MdEdit />
                    <input
                      ref={profileInputRef}
                      type="file"
                      onChange={handleSelectProfile}
                      name="profile_photo"
                      hidden
                    />
                  </div>
                )}
              </div>
              <div className="border rounded-xl bg-[#F9F9F9] w-full flex justify-between items-center p-2">
                <span className="font-semibold">
                  <img src={Rupee} alt="money icon" width={50} height={50} />
                </span>
                <span className="flex gap-1 items-center">
                  <FaRupeeSign /> {user?.wallet_balance || 0}
                </span>
              </div>
            </div>
            <div className="">
              <p className="flex items-center gap-2 justify-center font-semibold text-center">
                Referral Code: <span className="text-xl">{user?.referral_code}</span>{" "}
                <span
                  className="cursor-pointer"
                  title="Copy referral URL"
                  onClick={() => {
                    window.navigator.clipboard.writeText(
                      `https://card.navismartgame.com/auth/signup?page=Signup&referral_code=${user?.referral_code}`
                    );
                  }}
                >
                  <FaLink />
                </span>
              </p>
            </div>
            <div className="bg-red-bg text-white text-center text-sm font-semibold py-2">
              Online Multiplayer
            </div>
            <div className="flex justify-center gap-4 items-center">
              <div className="grid w-max">
                <div className="bg-red-bg p-2">
                  <FaRegThumbsUp className="text-white w-14 h-14" />
                </div>
                <p className="text-white text-center text-sm font-semibold bg-black">
                  {gameData?.winGame || "00"}
                </p>
              </div>
              <div className="grid w-max">
                <div className="bg-red-bg p-2">
                  <FaRegThumbsDown className="text-white w-14 h-14" />
                </div>
                <p className="text-white text-center text-sm font-semibold bg-black">
                  {gameData?.loseGame || "00"}
                </p>
              </div>
            </div>
          </div>
          <div className="grid gap-2">
            <div className="w-full flex justify-center items-center">
              <button
                type="button"
                className="bg-[#FFC9C8] rounded-full py-2 px-4"
                onClick={() => setIsEdit(!isEdit)}
              >
                Edit Profile
              </button>
            </div>
            <div className="w-full flex justify-center items-center">
              <button
                className="bg-[#FFC9C8] rounded-full py-2 px-4"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileBox;
