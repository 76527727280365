import apiRoutes from "api/apiRoutes";
import apiService from "api/apiService";
import axios from "axios";
import appConfig from "config/appConfig";
import dayjs from "dayjs";
import userLocalStorage from "hooks/userLocalStorage";
import React, { useEffect, useRef, useState } from "react";
import { BsDownload } from "react-icons/bs";
import { FaMinus, FaPlus, FaRupeeSign } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

interface Props {
  handleCloseModal: () => void;
}

const OrderTransaction = ({ handleCloseModal }: Props) => {
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const { getLocalStorage } = userLocalStorage();
  const walletTransactionRef = useRef();
  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [lastList, setLastList] = useState(false);
  const [loading, setLoading] = useState(false);

  const getWalletTransaction = () => {
    setLoading(true);
    const param = `?page=${page}&game=Card`;
    axios
      .get(appConfig.apiUrl.dev + apiRoutes.USER.ORDER_HISTORY + param, {
        headers: {
          Authorization: `Bearer ${getLocalStorage("token")}`,
        },
      })
      .then((response) => {
        if (response?.status == 200) {
          if (!response?.data?.data.length) {
            setLastList(true);
            return;
          }
          setPrevPage(page);
          setOrderHistoryData([...orderHistoryData, ...response?.data?.data]);
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!lastList && prevPage !== page) {
      getWalletTransaction();
    }
  }, [page, lastList, prevPage, orderHistoryData]);

  const onScroll = () => {
    if (walletTransactionRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        walletTransactionRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setPage(page + 1);
      }
    }
  };

  const downloadTransactions = (id: number) => {
    window.open(`https://panel.navismartgame.com/api/user/invoice/${id}`);
  };

  return (
    <div
      onScroll={onScroll}
      ref={walletTransactionRef}
      className="overflow-auto h-[500px] w-[90vw] max-w-full wt-scroll mt-4"
    >
      <div className="relative flex flex-col gap-5">
        {orderHistoryData?.length > 0 ?
          orderHistoryData.map((orderDetails, index) => {
          return (
            <div key={index} className="flex flex-col">
              <span>
                {orderHistoryData?.[index - 1]?.created_at?.slice(0, 10) !=
                orderDetails?.created_at?.slice(0, 10)
                  ? dayjs(orderDetails?.created_at).format("DD/MM/YYYY")
                  : ""}
              </span>
              <div className="p-2 border-b-[1px] border-gray-400 flex justify-between items-center">
                <div className={"flex gap-1 flex-col w-[50%]"}>
                  <span className="font-semibold text-lg flex items-center">
                    <FaRupeeSign /> {orderDetails?.amount}
                  </span>
                  <div>
                    <p className="text-sm">
                      Order ID:{" "}
                      <span className="font-semibold">{orderDetails?.id}</span>
                    </p>
                    <div className="flex justify-between">
                      <p className="text-sm">
                        Date:{" "}
                        <span className="font-semibold">
                          {dayjs(orderDetails?.created_at).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </span>
                      </p>
                      <p className="text-sm">
                        Status:{" "}
                        <span className="font-semibold">
                          {orderDetails?.status}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="bg-red-bg py-1 px-3 rounded-md text-white font-semibold text-xs grid gap-1"
                    onClick={() => downloadTransactions(orderDetails?.id)}
                  >
                    Download Invoice
                  </button>
                </div>
              </div>
            </div>
          );
        }) : (
          <div className="flex items-center justify-center h-[300px]">
            <p className="text-center font-semibold text-xl">No Transactions!</p>
          </div>
        )}
        {loading && (
          <div className="w-[30px] h-[30px] border-4 border-red-500 rounded-full mx-auto border-r-transparent animate-spin "></div>
        )}
      </div>
      <div className="absolute top-2 right-3 flex items-center gap-4">
        <button
          className="text-xl focus:outline-none"
          onClick={handleCloseModal}
        >
          <FaXmark color="black" />
        </button>
      </div>
    </div>
  );
};

export default OrderTransaction;
