import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Howl } from "howler";
import BGMusic from '../../assets/music/background-music.mp3';

export default function Music() {
  const { musicData } = useSelector((state: any) => state.game);
  const BGM = useRef<any>(null);

  useEffect(() => {
    // Initialize the Howl instance only once
    BGM.current = new Howl({
      src: [BGMusic],
      mute: !musicData,
      loop: true,  // Set loop to true
    });

    // Play or stop music based on musicData value
    if (musicData) {
      setTimeout(() => {
        BGM.current.play();
      }, 500);
    }

    // Stop the music when the component unmounts
    return () => {
      BGM.current.stop();
    };
  }, []);

  useEffect(() => {
    // Update mute status when musicData changes
    if (BGM.current) {
      BGM.current.mute(!musicData);
      if (musicData) {
        setTimeout(() => {
          BGM.current.play();
        }, 500);
      } else {
        BGM.current.stop();
      }
    }
  }, [musicData]);

  return null;
}
