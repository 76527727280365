import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProfileBox from "./ProfileBox";
import { FaRupeeSign, FaVolumeMute } from "react-icons/fa";
import { TbReport } from "react-icons/tb";
import { MdAdd } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import userLocalStorage from "hooks/userLocalStorage";
import { GoSignIn } from "react-icons/go";
import { appPath } from "routes/routes";
import GameHistory from "./GameHistory";
import AddMoney from "./AddMoney";
import LeaveWarnModal from "./LeaveWarnModal";
import { Avatar } from "@mui/joy";
import WithdrawModal from "./WithdrawModal";
import { FaVolumeHigh } from "react-icons/fa6";
import Rupee from "../assets/images/rupee.png";
import { useDispatch } from "react-redux";
import { setBackgroundMusic } from "store/gameReducer";
import withdrawalIcon from "../assets/images/withdrawal.png";
import enquiryIcon from "../assets/images/question.png";
import customerSupportIcon from "../assets/images/customer-support.png";
import Music from "./music/Music";
import OrderTransaction from "./OrderTransactions";
import referIcon from "../assets/images/refer.png";
import ReferModal from "./ReferModal";

const modalStyles = {
  p: 0,
  background: "none",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const Navbar = () => {
  const [activeModal, setActiveModal] = useState<
    "order-history" | "profile" | "wallet" | "withdraw" | "refer-and-earn" | ""
  >("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { prize, walletBalance, remainingTimeValue, gameDataObj, musicData } =
    useSelector((state: any) => state.game);
  const { getLocalStorage } = userLocalStorage();
  const userData = JSON.parse(getLocalStorage("user_data"));
  const stngRef = useRef<any>(null);
  const location = useLocation();
  const [showPrize, setShowPrize] = useState(false);
  const [time, setTime] = useState(60);
  const [openWarnModal, setOpenWarnModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTime(remainingTimeValue);
  }, [remainingTimeValue]);

  function openModal(
    value:
      | "order-history"
      | "profile"
      | "wallet"
      | "withdraw"
      | "refer-and-earn"
      | ""
  ) {
    setActiveModal(value);
    setIsModalOpen(true);
  }

  function closeModal() {
    setActiveModal("");
    setIsModalOpen(false);
  }

  useEffect(() => {
    if (location?.pathname == "/game/play") {
      setShowPrize(true);
    } else {
      setShowPrize(false);
    }
  }, [location]);

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "hidden") {
        dispatch(setBackgroundMusic(false));
      } else {
        dispatch(setBackgroundMusic(true));
      }
    });
  }, []);

  return (
    <>
      <Music />
      <LeaveWarnModal
        gameData={gameDataObj}
        openWarnModal={openWarnModal}
        setOpenWarnModal={setOpenWarnModal}
      />
      <div className="bg-[#fea62b] pt-2 flex justify-center items-center w-full text-white absolute z-10 lg:px-20 lg:py-2 text-nowrap border-b-2 border-b-red-500">
        {/* <img
          src={logo}
          height={"auto"}
          width={"20%"}
          className="cursor-pointer"
          onClick={() => {
            if (gameStartStatus) {
              if (time > 0) {
                setOpenWarnModal(true);
              }
            } else {
              navigate(appPath.home);
            }
          }}
        /> */}

        {/* <Link to={"/"}>
          <img src={logo} height={"auto"} width={"20%"} />
        </Link> */}
        <ul className="flex gap-2 md:gap-5 items-center">
          {/* Wallet */}
          {showPrize ? (
            <li>
              <button className="bg-white text-black p-2 flex items-center rounded-full">
                Table - <FaRupeeSign className="h-3 w-3" />
                {prize}
              </button>
            </li>
          ) : (
            <></>
          )}

          <li className="cursor-pointer">
            {userData ? (
              userData?.profile_photo ? (
                <div className="h-8 w-8 flex justify-center">
                  <img
                    src={userData?.profile_photo_url}
                    className="rounded-full"
                    height={32}
                    width={32}
                    alt="user"
                    onClick={() => openModal("profile")}
                  />
                </div>
              ) : (
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    background: "#c30f22",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  onClick={() => openModal("profile")}
                >
                  {userData?.name?.slice(0, 1)}
                </Avatar>
              )
            ) : (
              <Link to={appPath.signup}>
                <GoSignIn className="h-full w-full" />
              </Link>
            )}
            <p className="text-[10px] md:text-xs text-center mt-1">Profile</p>
          </li>

          <li ref={stngRef} className="cursor-pointer relative">
            {musicData ? (
              <FaVolumeHigh
                onClick={() => dispatch(setBackgroundMusic(false))}
                color="white"
                className="h-8 w-8"
              />
            ) : (
              <div onClick={() => dispatch(setBackgroundMusic(true))}>
                <FaVolumeMute color="white" className="h-8 w-8" />
              </div>
            )}
            <p className="text-[10px] md:text-xs text-center mt-1">Audio</p>
          </li>
          <li
            className="cursor-pointer text-center"
            onClick={() => openModal("order-history")}
          >
            <TbReport className="h-8 w-8 mx-auto" />
            <p className="text-[10px] md:text-xs text-center mt-1">History</p>
          </li>
          <li className="cursor-pointer" onClick={() => openModal("withdraw")}>
            <img
              src={withdrawalIcon}
              alt="money icon"
              width={40}
              height={40}
              className="w-[32px] md:h-[32px] object-contain mx-auto"
            />
            <p className="text-[10px] md:text-xs text-center mt-1">Withdraw</p>
          </li>
          <li className="ml-2 md:ml-10 flex items-center gap-2 text-lg cursor-pointer border-2 border-red-500 rounded-lg bg-white text-red-500 p-1">
            {/* <MdOutlineAccountBalanceWallet className="h-8 w-10" /> */}
            <img
              src={Rupee}
              alt="money icon"
              width={40}
              height={40}
              className="w-[30px] h-[20px] md:w-[40px] md:h-[40px] object-contain"
            />
            <div className="flex items-center font-semibold text-sm md:text-base">
              <FaRupeeSign color="red" className="h-3 w-3 md:h-4 md:w-4" />{" "}
              {parseFloat(walletBalance).toFixed(2)}
            </div>
            <button
              className="bg-red-bg text-xs md:text-sm text-white p-2 rounded-md font-semibold"
              onClick={(event) => {
                event.stopPropagation();
                openModal("wallet");
              }}
            >
              <MdAdd size={16} className="h-3 w-3 md:h-4 md:w-4" />
            </button>
          </li>
        </ul>
      </div>

      {!location.pathname.includes("/game/play") && (
        <div className="fixed md:bottom-2 bottom-16 right-2 flex gap-2">
          <button
            className="bg-red-bg rounded-full p-2"
            title="Refer&Earn"
            onClick={() => {
              setIsModalOpen(true);
              setActiveModal("refer-and-earn");
            }}
          >
            <img
              src={referIcon}
              alt="refer and earn"
              className="h-6 w-6 object-contain"
            />
          </button>
          <button
            className="bg-red-bg rounded-full p-2"
            title="Enquiry"
            onClick={() => {
              navigate(appPath.enquiry);
            }}
          >
            <img
              src={enquiryIcon}
              alt="enquiry"
              className="h-6 w-6 object-contain"
            />
          </button>
          <button
            className="bg-red-bg rounded-full p-2"
            title="Customer Support"
            onClick={() => {
              window.open("https://api.whatsapp.com/send?phone=919828522772");
            }}
          >
            <img
              src={customerSupportIcon}
              alt="customer support"
              className="h-6 w-6 object-contain"
            />
          </button>
        </div>
      )}

      <Modal open={isModalOpen} onClose={closeModal}>
        <ModalDialog sx={modalStyles}>
          <Typography>
            <div
              className={`bg-white border-2 border-red-600 rounded-2xl overflow-hidden flex flex-col gap-5 p-6 text-black m-auto w-[90%] ${
                activeModal === "order-history"
                  ? "lg:w-[50vw]"
                  : activeModal === "profile"
                  ? "lg:w-[30vw]"
                  : "lg:w-[40vw]"
              }`}
              style={{
                borderColor:
                  "linear-gradient(113.57deg, #830B0B 0%, #F90505 56.81%, #6D1212 103.3%)",
              }}
            >
              {activeModal === "profile" && (
                <ProfileBox handleCloseModal={closeModal} />
              )}
              {activeModal === "order-history" && (
                <OrderTransaction handleCloseModal={closeModal} />
              )}
              {activeModal === "wallet" && (
                <AddMoney handleCloseModal={closeModal} />
              )}
              {activeModal === "withdraw" && (
                <WithdrawModal handleCloseModal={closeModal} />
              )}
              {activeModal === "refer-and-earn" && (
                <ReferModal handleCloseModal={closeModal} />
              )}
            </div>
          </Typography>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default Navbar;
