import apiRoutes from "api/apiRoutes";
import apiService from "api/apiService";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft, FaRupeeSign } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TextInput from "./inputs/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import userLocalStorage from "hooks/userLocalStorage";
import { useDispatch } from "react-redux";
import { setWalletBalance } from "store/gameReducer";

const schema = yup.object().shape({
  amount: yup
    .string()
    .required("Amount is required.")
    .test(
      "min-amount",
      "Amount must be at least 500.",
      (value) => parseFloat(value) >= 500
    ),
  account_number: yup.string().required("Account Number is required."),
  account_holder_name: yup
    .string()
    .required("Account holder name is required."),
  ifsc: yup
    .string()
    .required("IFSC code is required.")
    .min(11, "IFSC code must be 11 digits.")
    .max(11, "IFSC code must be 11 digits."),
  // payment_mode: yup.string().required("Payment Mode is required."),
});
interface Props {
  handleCloseModal: () => void;
}

const WithdrawModal = ({ handleCloseModal }: Props) => {
  const [amount, setAmount] = useState<number>(0);
  const [showAlert, setSHowAlert] = useState<boolean>();
  const [showAlertMsg, setSHowAlertMsg] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { walletBalance } = useSelector((state: any) => state?.game);
  const { getLocalStorage, setLocalStorage } = userLocalStorage();
  const user = JSON.parse(getLocalStorage("user_data"));
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      amount: "",
      account_number: "",
      account_holder_name: "",
      ifsc: "",
      // payment_mode: "",
    },
    resolver: yupResolver(schema),
  });

  function onSubmit(data: any) {
    setLoading(true);
    const _form = new FormData();
    _form.append("phone", user?.phone);
    _form.append("payment_mode", "online");
    for (let key in data) {
      _form.append(key, data[key]);
    }
    apiService
      .post(apiRoutes.USER.WITHDRAW_AMOUNT, _form)
      .then((response) => {
        if (response?.code == 200) {
          setAmount(0);
          handleCloseModal();
          toast.success("Withdrawal request is submited successfully.");
          updateWalletBalance();
        } else {
          throw response;
        }
      })
      .catch((error) => {
        console.log(error);
        setSHowAlertMsg(error?.message);
        toast.error(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const updateWalletBalance = () => {
    apiService
      .get(apiRoutes.USER.WALLET_BALANCE)
      .then((response) => {
        if (response) {
          let user = JSON.parse(getLocalStorage("user_data"));
          user.wallet_balance = response?.data;
          setLocalStorage("user_data", JSON.stringify(user));
          dispatch(setWalletBalance(response?.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleAmountChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    // Allow empty string or valid number
    if (Number(value) >= 500) {
      const finalAmount = Number(value)-(Number(value)*30)/100;
      setAmount(finalAmount);
    }
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center gap-2">
        <FaArrowLeft onClick={handleCloseModal} />
        <span className="text-xl font-semibold">Withdraw Money</span>
      </div>

      <div className="flex flex-col w-full items-center">
        <div className="flex items-center gap-2">
          <FaRupeeSign />
          <span className="text-4xl font-semibold">{walletBalance}</span>
        </div>
        <span>Total Amount</span>
      </div>

      <div className="flex flex-col gap-3">
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="amount"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <div>
                <label className="flex items-center">
                  Enter Amount (<FaRupeeSign />)
                </label>
                <span className="border-b-[1px] border-b-gray-500 flex items-center">
                  <FaRupeeSign className="text-sm" />
                  <input
                    {...field}
                    type="number"
                    // value={amount}
                    onChange={(e) => {
                      field.onChange(e); // Preserve react-hook-form behavior
                      // Your custom onChange logic
                      handleAmountChange(e);
                    }}
                    className="focus:outline-none text-lg font-semibold"
                    placeholder="0"
                  />
                </span>
                {errors?.amount?.message && (
                  <span className="text-sm text-red-600">
                    {errors?.amount?.message}
                  </span>
                )}
              </div>
            )}
          />
          {amount > 0 && (
            <span className="text-sm font-semibold">
              After 30% TDS deduction, you will receive ₹{amount}
            </span>
          )}
          <div className="grid md:grid-cols-2 gap-4 mt-4">
            <Controller
              name="account_number"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <label className="flex items-center">Account Number</label>
                  <span className="border-b-[1px] border-b-gray-500 flex items-center">
                    <input
                      {...field}
                      type="number"
                      placeholder="Enter account number"
                      className="focus:outline-none"
                    />
                  </span>
                  {errors?.account_number?.message && (
                    <span className="text-sm text-red-600">
                      {errors?.account_number?.message}
                    </span>
                  )}
                </div>
              )}
            />
            <Controller
              name="account_holder_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <label className="flex items-center">
                    Account Holder Name
                  </label>
                  <span className="border-b-[1px] border-b-gray-500 flex items-center">
                    <input
                      {...field}
                      type="text"
                      placeholder="Enter account holder name"
                      className="focus:outline-none"
                    />
                  </span>
                  {errors?.account_holder_name?.message && (
                    <span className="text-sm text-red-600">
                      {errors?.account_holder_name?.message}
                    </span>
                  )}
                </div>
              )}
            />
            <Controller
              name="ifsc"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <label className="flex items-center">IFSC</label>
                  <span className="border-b-[1px] border-b-gray-500 flex items-center">
                    <input
                      {...field}
                      type="text"
                      placeholder="Enter IFSC"
                      className="focus:outline-none"
                    />
                  </span>
                  {errors?.ifsc?.message && (
                    <span className="text-sm text-red-600">
                      {errors?.ifsc?.message}
                    </span>
                  )}
                </div>
              )}
            />
            {/* <Controller
              name="payment_mode"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <label className="flex items-center">Payment Mode</label>
                  <div className="flex items-center gap-4">
                    <label className="flex items-center gap-2 cursor-pointer">
                      <input
                        {...field}
                        type="radio"
                        // placeholder="offline/online"
                        className="focus:outline-none"
                        value={"online"}
                      />
                      Online
                    </label>
                    <label className="flex items-center gap-2 cursor-pointer">
                      <input
                        {...field}
                        type="radio"
                        // placeholder="offline/online"
                        className="focus:outline-none"
                        value={"offline"}
                      />
                      Offline
                    </label>
                  </div>
                  {errors?.payment_mode?.message && (
                    <span className="text-sm text-red-600">
                      {errors?.payment_mode?.message}
                    </span>
                  )}
                </div>
              )}
            /> */}
          </div>
          <button className="py-2 px-5 bg-red-300 text-black mt-5 rounded-full m-auto w-fit tracking-wide">
            {loading ? "processing..." : "withdraw"}
          </button>

          <span className="text-xs text-black font-semibold mt-4">
            Note: Minimum Withdrawl amount must be 500.
          </span>
        </form>
      </div>
    </div>
  );
};

export default WithdrawModal;
