const userLocalStorage = () => {
  const getLocalStorage = (key: string) => {
    return localStorage.getItem(key);
  };

  const setLocalStorage = (key: string, value: any) => {
    return localStorage.setItem(key, value);
  };

  const clearLocalStorage = () => {
    return localStorage.clear();
  };

  return { getLocalStorage, setLocalStorage, clearLocalStorage };
};

export default userLocalStorage;
