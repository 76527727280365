import React, { useEffect, useState } from "react";
import coinsImage from "assets/images/money.png";
import { useLocation, useNavigate } from "react-router-dom";
import { appPath } from "routes/routes";
import clsx from "clsx";

const GameResultScreen = () => {
  const [result, setResult] = useState(false);
  const [time, setTime] = useState(5);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.state?.status) {
      setResult(location?.state?.status);
    }
  }, []);

  useEffect(() => {
    const second = setInterval(() => {
      if (time == 0) {
        navigate(appPath.home);
      } else {
        setTime(time - 1);
      }
    }, 1000);

    return () => {
      clearInterval(second);
    };
  }, [time]);

  return (
    <div
      className="h-screen w-screen flex items-center justify-center text-white"
      style={{
        background:
          "radial-gradient(50% 50% at 50% 50%, #DA1E1E 0%, #DC2525 54%, #AD1717 100%)",
      }}
    >
      <div
        className={`box-border rounded-3xl h-1/2 w-3/4 lg:w-1/2 xl:w-1/4 flex flex-col justify-center border-8 items-center 
         gap-8 shadow-lg shadow-gray-200 hover:cursor-pointer border-red-800`}
        style={{
          background: result
            ? "radial-gradient(circle, #57cf48 0%, #40ad44 54%, #087F23 100%)"
            : "radial-gradient(circle, #f36767 0%, #DC2626 50%, #7F1D1D 100%)",
        }}
      >
        <img
          src={coinsImage}
          className={clsx("object-contain", !result && "rotate-180")}
        />
        <span className="font-semibold text-4xl">
          You {result ? "Win" : "Lost"}
        </span>
        <div>Redirecting in {time}...</div>
        {/* <div className="w-full flex flex-col gap-2 items-center">
          <span className="font-semibold text-2xl">Play Again?</span>

          <div className="flex items-center gap-4">
            <button
              className="px-4 py-2 bg-green-700 shadow-lg text-white font-semibold uppercase rounded"
              onClick={() => {
                navigate(appPath.gamePlay);
              }}
            >
              Yes
            </button>
            <button
              className="px-4 py-2 bg-white text-black font-semibold uppercase rounded"
              onClick={() => {
                navigate(appPath.home);
              }}
            >
              No
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default GameResultScreen;
