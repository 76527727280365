import appConfig from "config/appConfig";
import userLocalStorage from "hooks/userLocalStorage";
import React from "react";
import { BsCopy, BsShare } from "react-icons/bs";
import { FaArrowLeft, FaShare, FaWhatsapp } from "react-icons/fa";
import { toast } from "react-toastify";
import { appPath } from "routes/routes";

const ReferModal = ({ handleCloseModal }: any) => {
  const { getLocalStorage } = userLocalStorage();
  const user = JSON.parse(getLocalStorage("user_data"));

  return (
    <div>
      <div className="flex justify-between items-center w-[90vw] max-w-full text-sm font-semibold">
        <div className="flex items-center gap-2">
          <FaArrowLeft onClick={handleCloseModal} className="cursor-pointer" />
          <span className="text-lg text-black border-2 border-red-500 bg-white rounded-md py-2 px-4 absolute -top-5 left-[50%] -translate-x-[50%]">
            Refer & Earn
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-6 mt-4">
        <div className="rounded-xl bg-[#F9F9F9] w-full flex flex-col px-4 gap-6 relative">
          <div className="flex gap-2 items-center justify-center">
            <span className="text-xl font-semibold">
              Referral Code: {user?.referral_code}
            </span>
            <span
              className="cursor-pointer"
              title="Copy referral URL"
              onClick={() => {
                window.navigator.clipboard
                  .writeText(user?.referral_code)
                  .then(() => {
                    toast.info("Referral Code copied to clipboard.");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              <BsCopy />
            </span>
          </div>
          <div className="flex items-center gap-2 justify-center">
            <p className="flex items-center gap-2 text-sm font-semibold">
              <span>
                Copy Referral URL:{" "}
              </span>
              <span className="w-[250px] line-clamp-1">
                {window.location.origin}
                {appPath.signup}?referral_code={user?.referral_code}
              </span>
            </p>
            <button
              className="cursor-pointer"
              title="Copy referral URL"
              onClick={() => {
                window.navigator.clipboard
                  .writeText(
                    `${window.location.origin}${appPath.signup}?referral_code=${user?.referral_code}`
                  )
                  .then(() => {
                    toast.info("URL copied to clipboard.");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              <BsCopy />
            </button>
          </div>
          <p className="text-center">
            Invite your friends to join the game and earn 10% of their first
            transaction.
          </p>
          <div>
            <p className="font-semibold text-lg text-center">Share Now:</p>
            <div className="mt-4 flex justify-center items-center gap-8">
              <button
                className="bg-red-bg text-white rounded-lg p-2"
                onClick={() => {
                  const message = `Let’s play Card and win together! \n 💥 Use my referral code: "${user.referral_code}" \n 🔥 Sign up now and enter my code in the referral input ! \n 🌟 Play now on our website: ${appConfig.domain}`;
                  const data = {
                    title: "Referral URL",
                    text: message,
                    url: `${window.location.origin}${appPath.signup}?referral_code=${user?.referral_code}`,
                  };
                  window.navigator.share(data);
                }}
              >
                <BsShare size={40} />
              </button>
              <button
                className="bg-red-bg text-white rounded-lg p-2"
                onClick={() => {
                  const message = `Let’s play Card and win together! \n 💥 Use my referral code: *"${user.referral_code}"* \n 🔥 Sign up now and enter my code in the referral input! \n 🌟 Play now on our website: ${appConfig.domain}`;
                  window.open(
                    `https://api.whatsapp.com/send?text=${encodeURIComponent(
                      message
                    )}`,
                    "_blank"
                  );
                }}
              >
                <FaWhatsapp size={40} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferModal;
