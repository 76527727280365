import apiRoutes from "api/apiRoutes";
import apiService from "api/apiService";
import clsx from "clsx";
import React, { useState } from "react";
import { FaArrowLeft, FaHistory, FaRupeeSign } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import GameHistory from "./GameHistory";

interface Props {
  handleCloseModal: () => void;
}

const preDefinedAmounts = ["500", "1000", "5000"];

const AddMoney = ({ handleCloseModal }: Props) => {
  const [amount, setAmount] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setSHowAlert] = useState<boolean>();
  const { walletBalance } = useSelector((state: any) => state?.game);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState("");
  const [finalAmount, setFinalAmount] = useState(0);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (!amount) {
      setSHowAlert(true);
      setShowError("Amount is required.");
      return;
    }
    setLoading(true);
    const _form = new FormData();
    _form.append("amount", amount);
    _form.append("game", "Card");
    apiService
      .post(apiRoutes.ORDER.CREATE_ORDER, _form)
      .then((response) => {
        // console.log(response);
        // e.preventDefault();
        // setAmount("");
        // handleCloseModal();
        // toast.success("Cash added successfully");
        handlePaymentOrder(response?.data?.id);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function handlePaymentOrder(id: any) {
    const _formData = new FormData();
    _formData.append("amount", amount);
    _formData.append("order_id", id);
    apiService
      .post(apiRoutes.ORDER.CREATE_PAYMENT_ORDER, _formData)
      .then((response) => {
        // console.log(response);
        window.open(response?.data?.url);
        setAmount("");
        handleCloseModal();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleAmountChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    // Allow empty string or valid number
    if (value === "" || !isNaN(Number(value))) {
      if (Number(value) < 100) {
        setShowError("The minimum amount required to add is 100.");
        setSHowAlert(true);
        setFinalAmount(0);
      } else {
        setShowError("");
        setSHowAlert(false);
        const finalVal = (28 / 100) * Number(value);
        setFinalAmount(Number(value) - finalVal);
      }
      setAmount(value);
    }
  }

  return (
    <>
      {showModal ? (
        <GameHistory handleCloseModal={() => setShowModal(false)} />
      ) : (
        <div className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <FaArrowLeft onClick={handleCloseModal} />
              <span className="text-xl font-semibold">Add Money</span>
            </div>
            <div
              className="flex items-center gap-1 text-sm text-red-500 underline font-semibold cursor-pointer"
              onClick={() => setShowModal(true)}
            >
              <FaHistory />
              History
            </div>
          </div>

          <div className="flex flex-col w-full items-center">
            <div className="flex items-center gap-2">
              <FaRupeeSign />
              <span className="text-4xl font-semibold">{walletBalance}</span>
            </div>
            <span>Total Amount</span>
          </div>

          <div className="flex flex-col gap-3">
            {/* <span className="text-2xl font-semibold">Add Money</span> */}
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <label
                className={clsx(
                  "flex items-center",
                  showAlert && "text-red-500"
                )}
              >
                Enter Amount (<FaRupeeSign />)
              </label>
              <span
                className={clsx(
                  "border-b-[1px] flex items-center",
                  showAlert ? "border-b-red-500" : "border-b-gray-500"
                )}
              >
                <FaRupeeSign className="text-sm" />
                <input
                  type="text"
                  value={amount}
                  onChange={handleAmountChange}
                  className="focus:outline-none text-lg font-semibold w-full"
                  placeholder="0"
                />
              </span>
              {showAlert && (
                <span className="text-sm text-red-600">{showError}</span>
              )}
              {/* {finalAmount > 0 && (
                <div>
                  <p className="text-sm font-bold">
                    After 28% GST deduction, you will receive ₹{finalAmount}
                  </p>
                </div>
              )} */}
              <div className="flex items-center justify-center gap-4 mt-4">
                {preDefinedAmounts?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center gap-1 bg-red-300 text-black py-1 px-3 rounded-lg cursor-pointer"
                      onClick={() => {
                        const finalVal = (28 / 100) * Number(item);
                        setFinalAmount(Number(item) - finalVal);
                        setAmount(item);
                      }}
                    >
                      <FaRupeeSign />
                      {item}
                    </div>
                  );
                })}
              </div>
              <button
                className={clsx(
                  "py-2 px-5 bg-red-300 text-black mt-5 rounded-full m-auto w-fit tracking-wide",
                  loading && "pointer-events-none",
                  showAlert && "pointer-events-none bg-gray-400"
                )}
              >
                {loading ? "Processing..." : "Proceed to Add"}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddMoney;
