import React, { useState } from "react";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";

const modalStyles = {
  p: 0,
  background: "none",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

type CommonModalProps = {
  isModalOpen: boolean;
  setIsModalOpen?: (value: any) => void;
  content: any;
};

const CommonModal = ({
  isModalOpen,
  setIsModalOpen,
  content,
}: CommonModalProps) => {
  function closeModal() {
    if (setIsModalOpen) setIsModalOpen(false);
  }

  return (
    <Modal open={isModalOpen} onClose={closeModal}>
      <ModalDialog sx={modalStyles}>
        <Typography>
          <div
            className={`bg-white border-2 border-red-600 rounded-2xl overflow-hidden flex flex-col gap-5 p-6 text-black m-auto w-[80vw] md:w-max
            }`}
            style={{
              borderColor:
                "linear-gradient(113.57deg, #830B0B 0%, #F90505 56.81%, #6D1212 103.3%)",
            }}
          >
            {content}
          </div>
        </Typography>
      </ModalDialog>
    </Modal>
  );
};

export default CommonModal;
