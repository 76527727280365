import clsx from "clsx";
import React, { forwardRef } from "react";

interface InputProps {
  type?: string;
  value?: any;
  onChange?: any;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  error?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  required?: boolean;
  name?: string;
  variant?: "primary" | "secondary";
}

const TextInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = "text",
      label,
      value,
      onChange,
      disabled,
      placeholder,
      autoFocus,
      error,
      startIcon,
      endIcon,
      required,
      name,
      variant = "primary",
    },
    ref
  ) => {
    return (
      <div className="text-left w-full">
        {label && (
          <label className="block mb-1 font-medium text-gray-800">
            {required && <span className="text-red-600 mr-1">*</span>}
            {label}
          </label>
        )}
        <div className="relative flex items-center">
          {startIcon && (
            <span className="absolute left-3 text-gray-500">{startIcon}</span>
          )}
          <input
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            autoFocus={autoFocus}
            autoComplete="off"
            ref={ref}
            className={clsx(
              "outline-none border border-transparent border-[1px] border-white rounded-full block w-full pl-5 py-3.5",
              startIcon ? "pl-10" : "pl-3",
              error && "border border-red-500",
              endIcon ? "pr-10" : "",
              variant == "primary" ? "bg-[#3E3E3E]" : "bg-[#E8EDF2]",
              variant == "primary" ? "text-white" : "text-[#3E3E3E]"
            )}
          />
          {endIcon && (
            <span className="absolute right-3 text-gray-500">{endIcon}</span>
          )}
        </div>
        {error && <div className="mt-1 pl-2 text-xs text-red-600 font-semibold">{error}</div>}
      </div>
    );
  }
);

export default TextInput;
