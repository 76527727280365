import React, { useEffect, useRef, useState } from "react";
import clock from "assets/images/timer.png";
import { useNavigate } from "react-router-dom";
import { appPath } from "routes/routes";
import dayjs from "dayjs";
import { echo } from "utils/pusher/PusherClient";
import { teamSize } from "types/enum";
import apiService from "api/apiService";
import apiRoutes from "api/apiRoutes";
import { useDispatch } from "react-redux";
import { setRemainingTimeValue } from "store/gameReducer";
import { useSelector } from "react-redux";

const TimerComponent = ({
  remainingNumber,
  gameTime,
  gameType,
  gameData,
  remainingTime,
  setRemainingTime,
}) => {
  const timerRef = useRef<any>(null);
  const [timeLeft, setTimeLeft] = useState(timerRef.current);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { gamePlayers } = useSelector((state: any) => state?.game);

  const updateGame = () => {
    const _form = new FormData();
    _form.append("card_game_session_id", gameData?.gameSession?.id);
    apiService
      .post(apiRoutes.GAME.GAME_UPDATE, _form)
      .then((response) => {
        if (response?.data) {
          navigate(appPath.gameResult, {
            state: {
              status: false,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // if (gamePlayers == teamSize.single) {
    //   timerRef.current = 30;
    // } else {
      // }
      timerRef.current = 60;
  }, []);

  useEffect(() => {
    if (gameType == teamSize.multi) {
      if (gameTime) {
        // Set up the timer to update every 1 second
        const intervalId = setInterval(() => {
          if (timerRef.current > 0) {
            timerRef.current -= 1; // Decrement the time in the ref
            setTimeLeft(timerRef.current); // Update the state for rendering
            setRemainingTime(timerRef.current);
            dispatch(setRemainingTimeValue(timerRef.current));
          } else {
            updateGame();
          }
        }, 1000);

        return () => clearInterval(intervalId);
      }
    } else {
      const intervalId = setInterval(() => {
        if (timerRef.current > 0) {
          timerRef.current -= 1; // Decrement the time in the ref
          setTimeLeft(timerRef.current); // Update the state for rendering
          setRemainingTime(timerRef.current);
          dispatch(setRemainingTimeValue(timerRef.current));
        } else {
          updateGame();
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [remainingNumber, gameTime]);

  return (
    <div className="font-semibold flex flex-col justify-center items-center">
      <div className="relative h-20 w-16 md:h-24 md:w-20 flex justify-center items-center">
        <img src={clock} alt="timer" className="absolute h-full w-full" />
        <span className="flex flex-col justify-center items-center mt-4">
          <span className="text-xl md:text-3xl">{timeLeft}</span>
          <span>sec</span>
        </span>
      </div>
      <span className="md:text-xl">Timer</span>
    </div>
  );
};

export default TimerComponent;
